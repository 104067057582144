* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.textHeader {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textBody {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.links:hover {
  background-color: #ff061b;
}
